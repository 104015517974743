<template>
  <div style="overflow: hidden;">
    <van-nav-bar
        v-if="1==2"
        title="政务服务登录"
        left-text="返回"
        right-text=""
        left-arrow
        @click-left="onBack"
    />
    <div class="van-multi-ellipsis--l3 padding-10" style="text-align: center;margin-top: 200px;">
      {{ message }}
    </div>
    <van-row v-if="isHome">
      <van-col span="8"></van-col>
      <van-col span="8"><van-button icon="wap-home" type="info" :to="homeUrl">返回首页</van-button></van-col>
      <van-col span="8"></van-col>
    </van-row>
    <Loading :loding-txt="lodingTxt" :is-show="isShow"/>
  </div>
</template>

<script>
import { loginOut } from '../../api/zwfu'
import {getKey, setKey} from "../../utils/cookie";
import Loading from "../../components/logding";
export default {
  name: "zwfwout",
  components: { Loading },
  data(){
    return {
      isShow: false,
      isHome: false,
      isLogin: (getKey(process.env.VUE_APP_H5_IS_LOGIN) || 'false') == 'true' ? true : false,
      lodingTxt: '加载中...',
      homeUrl:process.env.VUE_APP_H5_GO_HOME_URL,
      message: '',
      zwfwToken: '',
      accessLink: this.$route.query.accessLink
    }
  },
  created() {
    this.out()
  },
  methods: {
    out(){
      const that = this
      if (!that.zwfwToken) that.zwfwToken = ''
      if (!that.isLogin){
        that.message = '您还没有登录！'
        that.isHome = true
        return;
      }
      that.isShow = true
      loginOut().then(response => {
        that.isShow = false
        if (response.code == process.env.VUE_APP_SUCCESS_CODE){
          setKey(process.env.VUE_APP_USER_ID,'')
          setKey(process.env.VUE_APP_USER_NAME,'')
          //是否已经注册
          setKey(process.env.VUE_APP_USER_IS_REG,'')
          //是否已绑定
          setKey(process.env.VUE_APP_USER_IS_REG,0)
          //是否已实名
          setKey(process.env.VUE_APP_USER_IS_REG, '')
          this.$router.push(that.homeUrl)
        }else{
          that.message = response.message
          that.isHome = true
        }
      }).catch(() => {
        that.isShow = false
        that.message = '服务请求出错，请稍后再试！'
        that.isHome = true
      })
    },
    onBack : function (){
      this.$router.go(-2);
    }
  }
}
</script>

<style scoped>

</style>